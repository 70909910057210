export class AddressGoogle {

  constructor(classeContainerFormBuilder) {
    this.autocompleteField = null
    this.latitudeField = null
    this.longitudeField = null
    this.googleAutocomplete = null
    this.classContainer = classeContainerFormBuilder
    this.init()
  }

  init() {
    //let self = this
    this.autocompleteField = $(this.classContainer + ' .localisation_field').get(0)

    if (!this.autocompleteField) return

    this.latitudeField = document.getElementById('localisation_lat')
    this.longitudeField = document.getElementById('localisation_long')
    this.googleAutocomplete = this.initGoogle()

    if (this.latitudeField && this.longitudeField) {

      // mise à jour des champs latitude et longitude si l’utilisateur clique
      this.googleAutocomplete.addListener('place_changed', this.clicked.bind(this))

      // si l’utilisateur tape une adresse, à la main, suite au click ou pas, on vide les champs latitude et longitude
      // c’est que l’adresse n’est pas juste pour lui
      this.autocompleteField.addEventListener('keyup', this.clearPositionField.bind(this))
    }
  }

  // initialisation Google
  initGoogle() {
    return new google.maps.places.Autocomplete(
      this.autocompleteField,
      {
        types: ['geocode'],
        componentRestrictions: {
          country: 'ca',
        },
      }
    )
  }

  geolocate() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        let geolocation = {lat: position.coords.latitude, lng: position.coords.longitude}
        this.restrictArea(geolocation, position.coords.accuracy)
      })
    }
  }

  // restriction à un cercle centré sur l’utilisateur
  restrictArea(center, radius) {
    const circle = new google.maps.Circle({center: center, radius: radius})
    this.googleAutocomplete.setBounds(circle.getBounds())
  }

  // autocompletion au click
  clicked() {
    let place = this.googleAutocomplete.getPlace()

    // recuperation latitude et longitude et ajout dans les champs
    this.latitudeField.value = place.geometry.location.lat()
    this.longitudeField.value = place.geometry.location.lng()
  }

  // vide les champs latitude et longitude
  clearPositionField() {
    this.latitudeField.value = null
    this.longitudeField.value = null
  }
}
